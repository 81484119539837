import React from "react"
import styled from "styled-components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import AwesomeSlider from "react-awesome-slider"
import "react-awesome-slider/dist/styles.css"
import { colours } from "../style/colours"
import { connect } from "react-redux"
import { Link } from "gatsby"

class IntroHeader extends React.PureComponent {
  componentDidMount() {}

  render() {
    return (
      <Container>
        <AwesomeSlider bullets={false} fillParent>
          <Slide>
            <SlideImg src={require("../images/healthy.jpg")} />
            <SlideCopy>
              Browse our wide range of Health & Wellness products!
            </SlideCopy>
            <Link to="/shop">
              <SlideBtn>Shop Now</SlideBtn>
            </Link>
          </Slide>
          {/* <Slide>
            <SlideImg src={require("../images/oils.jpg")} />
            <SlideCopy>Wellness tips & advice for a healthier you!</SlideCopy>
            <Link to="/blog">
              <SlideBtn>Read More</SlideBtn>
            </Link>
          </Slide> */}
        </AwesomeSlider>
      </Container>
    )
  }
}

export default connect(
  state => ({
    isDarkMode: state.app.isDarkMode,
  }),
  null
)(IntroHeader)

const Container = styled.div`
  width: 100%;
  height: 50vh;
  background: white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;

  @media (min-width: 650px) {
    padding-top: 0;
  }

  div > div > div > div > div > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  div > div > div > ul > li {
    margin-bottom: 0;
  }
`
const Slide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
const SlideImg = styled.img`
  width: 100%;
  height: 100vh;
  margin: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`
const SlideCopy = styled.h1`
  font-weight: 600;
  font-size: 26px;
  color: white;
  position: relative;
  text-align: center;
  z-index: 4;
  padding: 0 15%;
  text-shadow: 0 2px 7px rgba(0, 0, 0, 0.39);
  margin-bottom: 20px;

  @media (min-width: 550px) {
    font-size: 38px;
  }

  @media (min-width: 750px) {
    font-size: 46px;
  }
`
const SlideBtn = styled.button`
  background: ${colours.maroonDark};
  color: white;
  border-radius: 5px;
  border: none;
  padding: 15px 20px;
  width: 200px;
  position: relative;
  z-index: 4;
  margin: 0 auto;
  font-weight: 600;
  font-family: "Muli";
  text-transform: uppercase;
  cursor: pointer;

  :focus {
    outline: none;
  }
`
