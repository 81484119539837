import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

//Components
import IntroHeader from "../components/intro-header"
import ShopContainer from "../components/shop-container"

/******************CONTAINERS***************/
const Container = styled.div``

const IndexPage = props => (
  <Layout>
    <SEO title="Home" />
    <Container>
      <IntroHeader />
    </Container>
    <ShopContainer collections={props.data.collections} />
  </Layout>
)

export default IndexPage

export const ShopQuery = graphql`
  query MyQuery {
    collections: allShopifyCollection(filter: {title: {ne: "Home page"}}) {
      edges {
        node {
          title
          products {
            title
            shopifyId
            availableForSale
            handle
            description
            priceRange {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            images {
              originalSrc
            }
            variants {
              compareAtPrice
              shopifyId
              title
              price
              availableForSale
              image {
                  originalSrc
              }
            }
          }
        }
      }
    }
  }
`
