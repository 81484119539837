import React, { Component } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import ProductItem from "../components/products/product-item"
import { colours } from "../style/colours"
import {
  updateProductCategory,
  updateCartCount,
  toggleShowCart,
  addToCart,
} from "../state/app"
import { convertShopifyId } from "../utils/utils"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  width: 100%;
  position: relative;
  margin: 0 auto;
`
const ShopCategorySection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
const Title = styled.h2`
  color: ${colours.maroonDark};
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 50px;
  font-size: 24px;

  ::after {
    content: '';
    width: 95%;
    margin: 0 auto;
    display: block;
    margin-top: 6px;
    background: ${colours.greenDark};
    height: 2px;
  }
`
const ProductsContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  max-width: 1000px;

  @media (max-width: 750px) {
    grid-template-columns: 50% 50%;
  }
`
const Break = styled.hr`
  width: 100%;
  height: 1px;
  opacity: 0.4;
  background: ${colours.greenLight};
`
const SeeMoreBtn = styled.button`
  color: white;
  background: ${colours.greenDark};
  border: 1px solid ${colours.greenLight};
  outline: none;
  text-transform: uppercase;
  padding: 7px 15px;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 40px;
  cursor: pointer;

  :hover {
    background: ${colours.greenDark};
    color: white;
  }
`

class ShopContainer extends Component {
  state = {}

  handleNavigateToProducts = category => {
    this.props.dispatch(updateProductCategory(category))
    navigate("/shop")
  }

  handleAddToCart = product => {
    const { dispatch, shoppingCart } = this.props

    let productsToReturn = shoppingCart

    //Check if the product is already in the cart
    const obj = shoppingCart.find(el => el.shopifyId === product.shopifyId)

    if (obj) {
      //find the index and adjust the count
      let index = shoppingCart.indexOf(obj)
      productsToReturn[index].count = productsToReturn[index].count + 1

      //Dispatch new cart with updated counts to state
      dispatch(updateCartCount(productsToReturn))
      dispatch(toggleShowCart(true))
    } else {
      const productWithFields = {
        ...product,
        count: 1,
        variantId: convertShopifyId(product.variants[0].shopifyId)
        
      }
      dispatch(addToCart(productWithFields))
      dispatch(toggleShowCart(true))
    }
  }

  render() {
    const { collections } = this.props
    return (
      <Container>
        {collections.edges.map((collection, index) => (
          <ShopCategorySection key={index}>
            <Title>{collection.node.title}</Title>
            <ProductsContainer>
              {collection.node.products.slice(0, 4).map((product, index) => (
                <ProductItem
                  key={index}
                  product={product}
                  addToCart={this.handleAddToCart}
                />
              ))}
            </ProductsContainer>
            <SeeMoreBtn
              onClick={() =>
                this.handleNavigateToProducts(collection.node.title)
              }
            >
              View All
            </SeeMoreBtn>
            <Break />
          </ShopCategorySection>
        ))}
      </Container>
    )
  }
}


export default connect(
  state => ({
    selectedProductCategory: state.app.selectedProductCategory,
    shoppingCart: state.app.shoppingCart,
  }),
  null
)(ShopContainer)
